async function servicescarousel() {
  const blocks = await loadJs([".services-carousel"]);
  blocks.forEach((block) => {
    const swiper = new Swiper(".swiper", {
      // Optional parameters
      direction: "horizontal",
      loop: true,
      autoHeight: false,
      slidesPerView: 1.1,
      // Responsive breakpoints
      breakpoints: {
        640: {
          slidesPerView: 1.2,
          spaceBetween: 40,
        },
        992: {
          slidesPerView: 2.2,
          spaceBetween: 40,
        },
      },

      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    block.classList.add("loaded");
  });
}
