async function logocarousel() {
  const blocks = await loadJs([".logo-carousel"]);
  blocks.forEach((block) => {
    const swiper = new Swiper(".swiper", {
      // Optional parameters

      loop: true,
      freeMode: true,
      spaceBetween: 0,
      loop: true,
      autoplay: {
        delay: 1,
        disableOnInteraction: false,
      },
      autoplayDisableOnInteraction: false,
      freeMode: true,
      speed: 2500,
      direction: "horizontal",
      autoHeight: false,
      slidesPerView: 3,

      // Responsive breakpoints
      breakpoints: {
        // when window is <= 380px
        380: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        // when window is <= 516px
        516: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        // when window is <= 768px
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        // when window is <= 992px
        992: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        // when window is <= 1200px
        1200: {
          slidesPerView: 6,
          spaceBetween: 30,
        },
        1400: {
          slidesPerView: 8,
          spaceBetween: 30,
        },
      },

      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    block.classList.add("loaded");
  });
}
