function initializePage() {
  siteheader();
  ctablock();
  cover();
  featuredcontent();
  servicesaccordion();
  splitcontent();
  sitefooter();
  contentbuilder();
  cardsbuilder();
  servicescarousel();
  logocarousel();
  servicesFilter();
  contentsections();
}
document.addEventListener("DOMContentLoaded", function () {
  initializePage();
});
