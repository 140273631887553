async function contentbuilder() {
  const blocks = await loadJs([".content-builder"]);
  blocks.forEach((block) => {
    // init Swiper:
    const swiperContainer = block.querySelector(
      ".pl-relationship__group-swiper"
    );
    console.log("🚀 ~ blocks.forEach ~ swiperContainer:", swiperContainer);

    if (swiperContainer) {
      // init Swiper:
      const swiper = new Swiper(swiperContainer, {
        direction: "horizontal",
        loop: true,
        slidesPerView: "auto",

        navigation: {
          nextEl: block.querySelector(".swiper-button-next"),
          prevEl: block.querySelector(".swiper-button-prev"),
        },
      });
    }

    block.classList.add("loaded");
  });
}
