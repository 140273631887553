async function siteheader() {
  const blocks = await loadJs([".site-header"]);
  blocks.forEach((block) => {
    let burgerButton = document.querySelector(".site-header__burger-btn");
    let burgerButtonB = document.querySelector(
      ".site-header__burger-btn__burger"
    );

    let burgerWrapper = document.querySelector(".site-header__burger-wrapper");
    let menuWrapper = document.querySelector(".site-header__menu-wrapper");
    let bodyTag = document.body;

    let timeoutId; // Variable to store the timeout ID

    burgerWrapper.addEventListener("click", () => {
      menuWrapper.classList.toggle("site-header__menu-wrapper--open");
      burgerButton.classList.toggle("site-header__burger-btn--open");
      burgerButtonB.classList.toggle("site-header__burger-btn__burger--open");
      bodyTag.classList.toggle("menu-open-logo");

      if (bodyTag.classList.contains("menu-open")) {
        // If the "menu-open" class is now present, set a 2-second timeout
        timeoutId = setTimeout(() => {
          bodyTag.classList.remove("menu-open");
        }, 250);
      } else {
        // If the "menu-open" class is not present, clear the previous timeout (if any)
        bodyTag.classList.toggle("menu-open");

        clearTimeout(timeoutId);
      }
    });

    block.classList.add("loaded");
  });
}
