// import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from "swiper";
// import "swiper/swiper-bundle.css";

async function cover() {
  const blocks = await loadJs([".cover"]);
  blocks.forEach((block) => {
    block.classList.add("loaded");

    let videoUrl = block.dataset.embedUrl;
    videoPlayer(block, videoUrl);

    let coverImageEl = block.querySelector(".cover__image");
    console.log("test");

    if (coverImageEl && coverImageEl.dataset.animation == "pan_effect") {
      coverImageEl.classList.add("pan_effect");
    }

    let slideshowEl = block.querySelector(".cover__slideshow");
    let slides = Array.from(
      slideshowEl.querySelectorAll(".cover__slideshow-slide")
    );
    let currentSlideIndex = 0;
    let slideshowInterval;

    if (slideshowEl) {
      // Initially show the first slide
      slides[0].classList.add("show");

      // Change slide every 4 seconds
      slideshowInterval = setInterval(() => {
        // Remove the "show" class from the current slide
        slides[currentSlideIndex].classList.remove("show");

        // Update the current slide index
        currentSlideIndex = (currentSlideIndex + 1) % slides.length;

        // Add the "show" class to the new current slide
        slides[currentSlideIndex].classList.add("show");
      }, 4000);
    }
  });

  function videoPlayer(block, videoUrl) {
    var players = block.querySelectorAll(".player");
    players.forEach(function (player) {
      var videoId = getYouTubeVideoId(videoUrl);
      var embedUrl =
        "https://www.youtube.com/embed/" +
        videoId +
        "?autoplay=1&loop=1&mute=1&showinfo=0&controls=0&modestbranding=1&showinfo=0&rel=0&playlist=" +
        videoId;
      var iframe = document.createElement("iframe");
      iframe.setAttribute("src", embedUrl);
      iframe.setAttribute("allow", "autoplay");
      iframe.setAttribute("allowfullscreen", "");
      iframe.setAttribute("frameborder", "0");
      player.appendChild(iframe);
    });

    function getYouTubeVideoId(url) {
      var videoId = "";
      var regex = /[?&]v=([^&#]*)/;
      var match = regex.exec(url);
      if (match && match[1]) {
        videoId = match[1];
      }
      return videoId;
    }
  }

  // Get the heading container element
  const headingContainer = document.querySelector(".pl-heading");

  // Get all the heading reels inside the h2 element
  const headingReels = document.querySelectorAll(".pl-heading-reel");

  // Initialize index and delay variables
  let currentIndex = 0;
  let displayTime = 3000; // Adjust this value to control the display time of each heading reel
  let transitionTime = 0.5; // Adjust this value to control the transition time between heading reels

  // Function to animate heading reels
  function animateHeadingReels() {
    // Get the current heading reel
    const currentReel = headingReels[currentIndex];

    // Animate the current heading reel
    currentReel.style.opacity = "1";
    currentReel.style.transition = `opacity ${transitionTime}s, transform ${transitionTime}s`;
    currentReel.style.transform = "scale(1)";

    // Wait for the transition to complete
    setTimeout(() => {
      // Delay for the display time
      setTimeout(() => {
        currentReel.style.opacity = "0";
        currentReel.style.transform = "scale(0.5)";
        // Move to the next heading reel
        currentIndex++;

        // Check if we have reached the end of the reels
        if (currentIndex === headingReels.length) {
          currentIndex = 0; // Reset to the first reel
        }

        // Schedule the next animation
        setTimeout(animateHeadingReels, transitionTime * 1000); // Minimal wait time using the transition time
      }, displayTime);
    }, transitionTime * 2000); // Delay the start of the animation using the transition time

    // Update the width of the heading container
    updateHeadingContainerWidth();
  }

  // Update the width of the heading container
  function updateHeadingContainerWidth() {
    const firstHeadingText = document.querySelector(".pl-heading-a");
    const currentReel = headingReels[currentIndex];

    // Get the width of the first non-animated text
    const firstHeadingTextWidth = firstHeadingText.offsetWidth;

    // Get the width of the current incoming reel
    const currentReelWidth = currentReel.offsetWidth;

    // Set the width of the heading container
    // headingContainer.style.width = `${Math.max(
    //   firstHeadingTextWidth,
    //   currentReelWidth * 2
    // )}px`;
    document.querySelector(
      ".pl-heading-reels-container"
    ).style.width = `${Math.max(currentReelWidth)}px`;
    headingContainer.style.margin = "0 auto"; // Center the heading container using margin auto
  }

  // Check if the div exists before executing the code
  if (document.querySelector(".pl-heading-reels-container")) {
    // Execute updateHeadingContainerWidth on window resize
    window.addEventListener("resize", updateHeadingContainerWidth);

    // Start the animation and update the width of the heading container
    animateHeadingReels();
    updateHeadingContainerWidth();
  }
}
