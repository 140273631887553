async function servicesFilter() {
  const blocks = document.querySelectorAll(".services-filter");
  blocks.forEach((block) => {
    const accordionItems = block.querySelectorAll(".c-accordion__item");

    accordionItems.forEach((item) => {
      item.addEventListener("click", function () {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        } else {
          closeAllAccordionItems();
          item.classList.add("active");
        }
      });
    });

    function closeAllAccordionItems() {
      accordionItems.forEach((item) => {
        item.classList.remove("active");
      });
    }

    block.classList.add("loaded");
  });
}
